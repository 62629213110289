import store from "@/store";
import config from "@/config";

function Routes(parameter?: string) {
  if (!parameter) parameter = "";
  const SECURITY_BASE = `${config.backendUrl}/security`;
  return {
    BASE: `/locales/${store.state.language}/${store.state.language}.base.json`,
    CDN: `${config.cdnUrl}`,
    CLIENTS: `${config.backendUrl}/clients/${parameter}`,
    CLIENT_REPORT: `${config.backendUrl}/clientreport/${parameter}`,
    CLIENT_REPORT_EXCEL: `${config.backendUrl}/clientreport/${parameter}/excel`,
    CODES: "/locales/shared/codes.json/",
    COURSES: `${config.backendUrl}/courses/${parameter}`,
    DOCUMENTATION: `${config.backendUrl}/${parameter}/api-docs`,
    DOWNLOADS: `${config.backendUrl}/downloads`,
    FILTERS: `/locales/${store.state.language}/${store.state.language}.filters.json`,
    LOGIN: "/locales/shared/user.json",
    LEARNER: `${config.backendUrl}/learners`,
    MASTER_COURSES: `${config.backendUrl}/mastercourses`,
    MODERATORS: `${config.backendUrl}/moderators`,
    PLATFORM_USERS: `${config.backendUrl}/platformusers/${parameter}`,
    REGISTRATION: `${config.backendUrl}/registration`,
    ON_DEMAND: `${config.backendUrl}/on-demand`,
    ON_DEMAND_LICENSE_USER: `${config.backendUrl}/on-demand/event/${parameter}/license`,
    TRAINING_EVENTS: `${config.backendUrl}/learningEvent`,
    TRAINING_TIME_CARD: `${config.backendUrl}/learningEvent/time_card/${parameter}`,
    TRAINING_OD_TIME_CARD: `${config.backendUrl}/learningEvent/od_time_card/${parameter}`,
    USER_EVENTS: `${config.backendUrl}/eventreport/${parameter}`,
    SECURITY: `${SECURITY_BASE}`,
    SECURITY_USER: `${SECURITY_BASE}/${parameter}`,
    SECURITY_SEARCH: `${SECURITY_BASE}/search/${parameter}`,
    SECURITY_ROLES: `${SECURITY_BASE}/roles`,
    SECURITY_GRANT: `${SECURITY_BASE}/${parameter}/grant`,
    SECURITY_REVOKE: `${SECURITY_BASE}/${parameter}/revoke`,
    SECURITY_VALIDATE_GRANT: `${SECURITY_BASE}/validate_grant`,
    ENCRYPT: `${SECURITY_BASE}/encrypt`,
    SOCKET: `${config.socketUrl}`,
  };
}
export default Routes;
