<template lang="pug">
.user-menu(v-on-clickaway="hideUserMenu")
  p(data-name="user-menu", :class="{ 'text-high-contrast': light }", @click="onToggleUserMenu") {{ name }}
    span.user-image
      img(:src="require('@/assets/images/icons/profile.svg')", alt="Logo for Crucial Conversations for Accountability")
  .dropdown(:class="{ 'show-user-nav': isUserMenuVisible }")
    nav
      ul.list-unstyled
        li
          a(data-name="profile-button", @click="onProfileClick") Profile
        li
          a(data-name="logout-button", href="#", @click="onLogoutClick") Log Out
        li
          a(data-name="privacy-settings", @click="showPrivacySettings") Privacy Settings
        li
          a.with-icon(data-name="help-button", href="https://help.cruciallearning.com", target="_blank") Help
            img(src="@/assets/images/icons/launch-window.png")
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { directive as onClickaway } from "vue-clickaway";
import { mapState } from "vuex";
import { DateTime } from "luxon";
import { http } from "@/services/http";
import config from "@/config";
import Osano from "@/osano/model";

@Component({
  directives: { onClickaway: onClickaway },
  computed: { ...mapState("UserModule", ["user"]) },
})
export default class UserMenu extends Vue {
  @Prop({ required: false, type: Boolean }) readonly light?: boolean;

  private isUserMenuVisible = false;

  hideUserMenu(): void {
    this.isUserMenuVisible = false;
  }

  onLogoutClick(): void {
    this.$auditron.log(this.$auth.authUser, "USER", "platform.logout");
    this.$auth.logout();
  }

  showPrivacySettings(): void {
    this.onToggleUserMenu();
    Osano.cm.showDrawer("osano-cm-dom-info-dialog-open");
  }

  onProfileClick(): void {
    if (this.$router.currentRoute.path !== "/myprofile") {
      this.$router.push("/myprofile");
    }
    this.isUserMenuVisible = false;
  }

  onToggleUserMenu(): void {
    this.isUserMenuVisible = !this.isUserMenuVisible;
  }

  get name(): string {
    return `${this.$auth.authUser.given_name} ${this.$auth.authUser.family_name}`;
  }
}
</script>
<style scoped>
.with-icon {
  display: inline-grid;
  grid-template: 1fr / 7fr 1fr;
}
</style>
