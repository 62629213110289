import { PlatformUserModel } from "@/store/modules/User/model";
import { ClientContentModel } from "@/store/modules/Client/model";
import { EventContentModel } from "@cruciallearning/puddle/models/event";
import api from "@/services/api";
import config from "@/config";
import { http } from "@/services/http";
import { AuthUser } from "@cruciallearning/puddle/auth";
import { AuditronResponse, DistinctDataFields, TransactionOptions } from "@cruciallearning/puddle/models/auditron";

const Auditron = {
  log(
    target: AuthUser | PlatformUserModel | ClientContentModel | EventContentModel,
    action: string,
    scope: string,
    tags: string[] = [],
    oldValue?: string,
    newValue?: string,
    changeId?: string
  ): void {
    action = action.toUpperCase();
    let collection = "";
    let salesforceId: string | undefined;
    if (this.isPlatformUserModel(target) || this.isAuthUser(target)) {
      collection = "platformUser";
      salesforceId = target.clientAssociations?.at(0).salesforceId;
      this.addTag(tags, `${target.firstName}.${target.lastName}`);
    } else if (this.isClientContentModel(target)) {
      collection = "client";
      salesforceId = target.externalId;
    } else if (this.isEventContentModel(target)) {
      collection = "learningEvent";
      salesforceId = target.salesForceId;
      this.addTag(tags, target.referenceNumber);
    } else return;

    this.addTag(tags, action);
    this.addTag(tags, scope);

    api.auditron.add(
      config.backendUrl,
      {
        action: action,
        targetObjectId: target.id,
        targetCollection: collection,
        salesforceId: salesforceId,
        tags: tags,
        changes: [
          {
            _id: changeId,
            scope: scope,
            oldValue: oldValue,
            newValue: newValue,
          },
        ],
      },
      http
    );
  },

  addTag(tags?: string[], value?: string): void {
    if (!tags) tags = [];
    if (!value) return;

    const values = value.split(".");
    for (let newValue of values) {
      newValue = newValue.trim().toLowerCase();
      if (newValue && newValue !== "undefined" && newValue !== "null") {
        if (!tags.includes(newValue)) tags.push(newValue);
      }
    }
  },

  isAuthUser(target: unknown): target is AuthUser {
    return true;
  },
  isPlatformUserModel(target: unknown): target is PlatformUserModel {
    return true;
  },
  isClientContentModel(target: unknown): target is ClientContentModel {
    return true;
  },
  isEventContentModel(target: unknown): target is EventContentModel {
    return true;
  },

  async getPage(size: number, page: number, objectClass: string, objectId: string): Promise<AuditronResponse> {
    return await api.auditron.getPage(config.backendUrl, size, page, objectClass, objectId, http);
  },
  async getTransactions(options: TransactionOptions): Promise<AuditronResponse> {
    return await api.auditron.getTransactions(config.backendUrl, options, http);
  },
  async downloadTransactions(filename: string, options: TransactionOptions): Promise<void> {
    return await api.auditron.downloadTransactions(config.backendUrl, filename, options, http);
  },
  async downloadAttendanceRecord(event: EventContentModel): Promise<void> {
    return await api.auditron.downloadAttendanceRecord(config.backendUrl, event, http);
  },
  async getDistinctData(salesforceId?: string): Promise<DistinctDataFields> {
    return await api.auditron.getDistinctData(config.backendUrl, http, salesforceId);
  },
};
export default Auditron;
