import axios, { AxiosError, AxiosResponse } from "axios";
import { DownloadModel } from "@/store/modules/Download/model";
import { DownloadErrorModel } from "@/store/modules/Event/model";
import { http } from "@/services/http";
import routes from "@/services/routes";
import store from "@/store";
import util from "@/utils";
import { DateTime } from "luxon";

const Download = {
  getMaterial(
    body: {
      learningEventId: string;
      learnerSecurityId?: string;
    },
    onDownloadProgress?: (progressEvent: ProgressEvent) => void,
    isLg = true
  ): Promise<DownloadModel | null> {
    const date = DateTime.now().toISO({ includeOffset: false });
    const baseUrl = `${routes().DOWNLOADS}`;
    const url = isLg ? baseUrl : `${baseUrl}/prework`;
    return http
      .post<DownloadModel>(url, { ...body, date }, { timeout: 300000, onDownloadProgress })
      .then((response) => response.data)
      .catch((error: AxiosError) => {
        error.config.headers.Authorization = "Bearer";
        const errorModel: DownloadErrorModel = {
          browser: util.getPlatformAsString(),
          stacktrace: error.message,
        };
        this.setDownloadError(error, body.learningEventId, errorModel);
        return null;
      });
  },
  setDownloadError(
    error: Error | AxiosError | string | undefined,
    eventId: string,
    data: DownloadErrorModel
  ): Promise<AxiosResponse> {
    let message = "";
    if (axios.isAxiosError(error)) message = JSON.stringify(error);
    else if ((error as Error).stack) message = (error as Error).stack as string;
    else if ((error as Error).message) message = (error as Error).message;
    else if (typeof error === "string") message = error;

    data.stacktrace = message;
    return http.put(`${routes().TRAINING_EVENTS}/${eventId}/download/error`, data);
  },
};
export default Download;
